import debounce from 'lodash/debounce'
import {each, map, on, ajax, target, toggle, load} from '../modules/nw'

const hide = widget => toggle(widget.out, 'showSuggestions', false)
const clickFirstLink = (widget, event) => each('[href]', event.target)[0]?.click()
const handleInput = debounce(widget => {
    const val = widget.in.value
    toggle(widget.reset, 'd-none', !val)
    val.length >= 3 && ajax(target(widget.in), data => show(widget, data))
    val || hide(widget)
}, 600)

const show = (widget, data) => {
    toggle(widget.out, 'showSuggestions', data.length)
    load(widget.out, map(data, rec => `<li tabindex="-1">${rec}</li>`))
    each('li', li => keyup(li, widget, {...{ENTER: clickFirstLink}, ...NAV}), widget.out)
}

const focus = (el, offset) => {
    const list = each('li', el)
    let li = list.indexOf(document.activeElement) + offset
    li = list[(li < 0 ? list.length - 1 : li) % list.length]
    li && li.focus({preventScroll: true})
}

const keyup = (el, widget, handlers) => on(el, 'keyup', e => {
    (handlers[e.key.toUpperCase()] ?? handlers['*'])?.(widget, e)
    return false
})

const NAV = {
    ARROWUP: widget => focus(widget.out, -1),
    ARROWDOWN: widget => focus(widget.out, 1),
    ESCAPE: widget => hide(widget),
}

each('[data-toggle=search]', form => {
    const widget = {
        in: each('[data-toggle=search-input]', form)[0],
        out: each('[data-toggle=search-output]', form)[0],
        reset: each('button[type=reset]', form)[0],
        submit: each('button[type=submit]', form)[0],
    }
    keyup(widget.in, widget, {...{'*': handleInput}, ...NAV})
    on(form, 'reset', _ => handleInput(widget))

    if (window.innerWidth < 768) {
        document.getElementById('header').appendChild(widget.out) // WTF?
    }
})