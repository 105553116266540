import '../../scss/default.scss'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'lazysizes'
import './dropdown'
import './tooltip'

import 'jquery'

import {load} from '../modules/nw'
window.addEventListener('load', _ => load())