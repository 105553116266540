import $ from 'jquery'
import 'select2/dist/js/select2.full'

new class {
    constructor() {
        const renderItem = selection => {
            const originalOption = selection.element
            const icon = $(originalOption).data('icon') ? `<i class="${$(originalOption).data('icon')}"></i>` : ''
            return $(`<span>${icon} ${selection.text}</span>`)
        }

        $('.dropdown select').each((i, element) => {
            $(element).select2({
                minimumResultsForSearch: Infinity,
                dropdownCssClass: $(element).closest('.dropdown').hasClass('small') ? 'small' : '',
                width: '100%',
                allowHtml: true,
                templateSelection: renderItem,
                templateResult: renderItem
            }).on('change', event => {
                if ($(event.currentTarget).closest('.href').length) {
                    window.location = $(event.currentTarget).val()
                }
            }).on('select2:open', () => $(element).closest('.dropdown').addClass('open'))
              .on('select2:close', () => $(element).closest('.dropdown').removeClass('open'))
        })

        $('.dropdown .label').click(function(){
            if ($(document).width() < 1060) {
                let select = $(this).closest('.dropdown').find('select')
                select.select2('open')
            }
        })
    }
}
