import $ from 'jquery';
import throttle from 'lodash/throttle'

const watch = () => {
    $('.stickyheader').toggleClass('stuck', $(window).scrollTop() > 22)
};

watch();

window.addEventListener('scroll', throttle(watch, 20));
window.addEventListener('resize', throttle(watch, 20));